button:disabled {
    /* background-color: #a8a8a8 !important; */
    cursor: no-drop;
}

.badge {
    text-transform: capitalize;
}

.calendar-post-badge {
    border-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
}

.badge-text {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
}

.badge.not_approved {background-color: rgb(230, 0, 0);}
.badge.send_for_approval {background-color: rgb(255, 196, 0); color: #000;}
.badge.approved {background-color: rgb(0, 209, 139);}

.logo {
    width: 302px;
    height: 56px;
    background-image: url(../img/logo.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
}

.border-blue {
    border: 1px solid var(--bg-color-blue);
    border-radius: 4px;
    padding: 14px 24px;
}

.small-title {
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    margin-bottom: 30px;
    color: var(--bg-color-darkgrey);
}

.small-subtitle {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 50px;
}

.padding-container {
    background-color: #fff;
    padding: 12px;
}

.close {
    background-image: url(../img/close.svg);
    background-repeat: no-repeat;
    height: 15px;
    width: 15px;
    position: absolute;
    top: 16px;
    right: 20px;
    border: none;
    background-color: transparent;
}

.custom-btn {
    border: none;
    border-radius: 4px;
    padding: 12px 33px;
    color: #fff;
    width: fit-content;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
}

.custom-btn.text-gray {
    color: var(--bg-color-darkgrey);
}

.custom-btn.arrow-right {
    padding-right: 43px !important;
    position: relative;
}

.custom-btn.arrow-right::after {
    content: url(../img/arrow.svg);
    position: absolute;
    top: 50%;
    right: 13px;
    transform: translateY(-45%);
}

.custom-btn.arrow-right.text-gray::after {
    content: url(../img/arrow-back.svg);
}

.custom-btn.btn-small {
    padding: 6px 16px;
    font-weight: 500;
    font-size: 13px;
}

.fb-button-icon {
    background-image: url(../img/Facebook_Logo_Secondary.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 25px;
    width: 25px;
}

.ig-button-icon {
    background-image: url(../img/Instagram_Glyph_White.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 25px;
    width: 25px;
}

.download-btn {
    border: none;
    height: 30px;
    width: 30px;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    background-image: url(../img/download.svg);
    background-repeat: no-repeat;
    background-size: contain;
    filter: invert(53%) sepia(99%) saturate(1861%) hue-rotate(353deg) brightness(95%) contrast(103%);
    transition: all 200ms ease-in-out;
}

.download-btn:hover {
    transform: scale(1.2);
}

.custom-dropdown {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: none;
    padding: 12px 15px 12px 33px;
    color: #fff;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    text-decoration: none;
}

.custom-dropdown:hover {
    color: #fff;
}

.dropdown-toggle::after {
    content: url(../img/arrow.svg);
    margin-left: 15px;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    transform: rotate(90deg);
}

#preview-wrap .dropdown-menu {
    padding: 0 15px !important;
}

#preview-wrap .dropdown-menu > li:not(:last-child) {
    border-bottom: 0.5px solid #a8a8a8;
} 

.custom-dropdown-item {
    background: #FFFFFF;
    border: none;
    border-radius: 4px;
    padding: 8px 10px;
    color: var(--bg-color-blue);
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
    line-height: 34px;
    white-space: nowrap;
}

.custom-dropdown-arrow {
    padding: 8px 10px;
    content: url(../img/arrow-blue.svg);
}

.link {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-decoration-line: underline;
    color: var(--bg-color-blue);
    background-color: transparent;
    border: none;
    padding: 0;
}

.pagination-button {
    border: none;
    border-radius: 2px;
    color: var(--bg-color-orange);
    background-color: #fff;
    padding: 5px 12px;
}

body.campaign .pagination-button {
    color: var(--bg-color-influeeColor);

}

.pagination-button.active {
    color: #fff;
    background-color: var(--bg-color-orange);  
}

body.campaign .pagination-button.active {
    background-color: var(--bg-color-influeeColor);
    color: #fff;
}

.ReactModal__Overlay {
    z-index: 500;
    background-color: rgba(0,0,0,0.25) !important;
}

.accordion-button {
    background-color: #f6790b6c;
    font-weight: 500;
    
}

.accordion-button:not(.collapsed) {
    color: #000;
    background-color: #f6790b6c;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon)
}

.accordion-button:focus {
    box-shadow: none;
}

/* FOMR AND INPUTS */
.form-floating {
    margin-bottom: 1rem;
}

/* Date */
.react-datepicker__input-container {
    height: 100%;
}

.react-datepicker__input-container input {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 36px;
    border: none;
    border-bottom: 1px solid #ced4da;
    width: 100%;
    height: 100%;
    padding: 0;
    background: transparent;
}

.datepicker-icon {
    background-image: url(../img/calendar.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 22px;
    height: 22px;
    margin: 4px 11px;
}

.react-datepicker__header {
    background-color: #fff !important;
    border-bottom: none !important;
    padding-bottom: 0px !important;
}

.react-datepicker__day-name {
    font-weight: 600;
    opacity: 0.4;
}

.react-datepicker__day--today {
    background-color: #f0f0f0;
    border-radius: 50% !important;
}

.react-datepicker__day:hover {
    border-radius: 50% !important;
}

.react-datepicker__day--selected {
    background-color: var(--bg-color-orange) !important;
    border-radius: 50% !important;
}

.react-datepicker__day--keyboard-selected {
    /* background-color: #f0f0f0 !important;
    color: #000 !important; */
    background-color: var(--bg-color-orange) !important;
    border-radius: 50% !important;
}

.react-datepicker__day--outside-month {
    opacity: 0.3;
}

.react-datepicker__navigation-icon::before,
.react-datepicker__year-read-view--down-arrow {
    border-color: #000 !important;
    border-width: 2px 2px 0 0 !important;
}

.react-datepicker__year-read-view--down-arrow {
    top: 2px !important;
}

.react-datepicker__navigation:hover *::before {
    border-color: var(--bg-color-lightgrey) !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    display: none !important;
}

.react-datepicker__year-dropdown {
    max-height: 150px !important;
    overflow-y: scroll;
}

.react-datepicker__year-dropdown .react-datepicker__navigation {
    height: 20px !important;
    width: 20px !important;
}

.react-datepicker__year-dropdown .react-datepicker__navigation--years-upcoming::before,
.react-datepicker__year-dropdown .react-datepicker__navigation--years-previous::before {
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: "";
    display: block;
    height: 9px;
    width: 9px;
    position: absolute;
    left: 50%;
    border-color: #000;
}

.react-datepicker__year-dropdown .react-datepicker__navigation--years-upcoming::before {
    top: 80%;
    transform: translate(-50%, -50%) rotate(-45deg);
}

.react-datepicker__year-dropdown .react-datepicker__navigation--years-previous::before {
    top: 30%;
    transform: translate(-50%, -50%) rotate(135deg);
}

.date-input-icon {
    width: 20px;
    height: 20px;
    background-image: url(../img/date.svg);
    margin-left: -20px;
}

/* Time */
input[type=time] {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 15px;
    line-height: 36px;
    flex-direction: row-reverse;
    border: none;
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    padding: 0;
}

input[type=time]::after {
    content: "";
    height: 1px;
    width: 100%;
    background:  linear-gradient(90deg, transparent 41px, #ced4da 41px);
    position: absolute;
    bottom: 0px;
}

input[type=time]:focus-visible,
.react-datepicker__input-container input:focus-visible {
    outline: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(100%) sepia(0%) saturate(3207%) hue-rotate(130deg) brightness(30%) contrast(80%);
    width: 22px;
    height: 22px;
    margin-right: 5px;
}

/* Checkbox */
input[type="checkbox"] {
    margin-right: 5px !important;
}
/* END FOMR AND INPUTS */

/* COMPANY */
.company-invites ul {
    list-style: none;
}

.company-invites .company-title {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 30px;
}
/* END COMPANY */

/* BRANDS */
.brand-item {
    display: flex;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    align-items: center;
}

.brand-logo {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 18px;
    background-color: #F2F2F2;
    border-radius: 4px;
    height: 50px;
    width: 50px;
    margin-right: 10px;
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.brand-edit {
    margin: 0 10px 0 5px;
    background-image: url(../img/gear.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 16px;
    width: 16px;
    cursor: pointer;
    filter: invert(43%) sepia(0%) saturate(0%) hue-rotate(345deg) brightness(93%) contrast(89%);
    min-width: 12px;
}

.remove-user {
    border: none;
    background-color: transparent;
    background-image: url(../img/close.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 12px;
    width: 12px;
    margin-right: 10px;
}

.brand-invite-title {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 25px;
    color: var(--bg-color-darkgrey)
}

.brand-invite {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: var(--bg-color-darkgrey);
    margin-bottom: 10px;
}

.brand-invite-small {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
    color: var(--bg-color-darkgrey)
}

.company-brands {
    list-style: none;
    margin-bottom: 0px;
    padding-left: 0px;
}

.company-brand {
    font-family: 'Open Sans';
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-top: 3px;
    display: flex;
    flex-wrap: wrap;
}

.add-brand {
    border: none;
    background-color: var(--bg-color-orange);
    height: 32px;
    width: 32px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-plus {
    background-image: url(../img/plus.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    height: 12px;
    width: 12px;
    filter: invert(100%) sepia(31%) saturate(7487%) hue-rotate(186deg) brightness(129%) contrast(100%);
}

.brand-button {
    width: 30px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: none;
}

.brand-button.edit {
    height: 28px;
    background-image: url(../img/post.svg);
}
.brand-button.delete {
    height: 23px;
    background-image: url(../img/delete.svg);
}
/* END BRANDS */

/* POSTS */
.posts {
    max-height: calc(800px - 140px);
    overflow-y: scroll;
    padding-right: 5px;
}

.post-logo {
    background-color: #F2F2F2;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin-right: 10px;
    min-width: 50px;
}

/* width */
.posts::-webkit-scrollbar {
    width: 3px;
}
  
/* Track */
.posts::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #DADADA;
    border-radius: 10px;
}
  
/* Handle */
.posts::-webkit-scrollbar-thumb {
    background: var(--bg-color-orange);
    border-radius: 10px;
}

.create-title {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 26px;
    line-height: 35px;
    color: var(--bg-color-blue);
    display: flex;
    margin-bottom: 20px;
    position: relative;
}

.create-title::before {
    content: url(../img/post-blue.svg);
    height: 26px;
    width: 26px;
    position: absolute;
    left: -35px;
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #797979;
    z-index: 20;
    opacity: 0.5;
}

.post-container {
    position: relative;
    z-index: 25;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px 45px 20px 45px;
}

.post-container .brand-logo {
    width: 61px;
    height: 61px;
    background-color: #DADADA;
    border-radius: 50%;
}

.post-container .brand-title {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: var(--bg-color-lightgrey);
    
}

.posts-on-plan-title {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}

.posts-on-plan-title span {
    font-family: 'Open Sans';
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
}

.calendar-post {
    display: flex;
    margin-bottom: 25px;
}

.calendar-post-img {
    background-color: #d5d5d5;
    margin-right: 8px;
    cursor: pointer;
    object-fit: cover;
}

.calendar-post-date {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    color: #4B4B4B;
    cursor: pointer;
}

.calendar-post-caption  {
    margin-bottom: 5px;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.calendar-post-status {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 10px;
    color: #fff;
    padding: 5px 15px;
    text-transform: uppercase;
    margin-right: 5px;
}

.calendar-post-status.draft,
.preview-status.draft {
    background-color: var(--bg-color-lightgrey);
}

.calendar-post-status.scheduled,
.preview-status.scheduled {
    background-color: var(--bg-color-orange);
}

.calendar-post-status.pending_client_approval,
.preview-status.pending_client_approval {
    background-color: var(--bg-color-blue);
}

.calendar-post-status.pending_internal_approval,
.preview-status.pending_internal_approval {
    background-color: lightcoral
}

.calendar-post-status.done,
.preview-status.done {
    background-color: var(--bg-color-green);
}

.calendar-post-status.posted,
.preview-status.posted {
    background-color: red;
}

.calendar-post-status.internally_approved,
.preview-status.internally_approved {
    background-color: var(--bg-color-influeeColor);
}

.calendar-post-social-media {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 9px;
    line-height: 12px;
    color: #fff;
    background-color: #adadad;
    height: 21px;
    width: 21px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendar-post-social-media.facebook::before {
    content: "FB";
}
.calendar-post-social-media.instagram::before {
    content: "IG";
}

.post-title {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: var(--bg-color-lightgrey);
    margin-bottom: 14px;
}

.inspiration-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--bg-color-lightgrey);
}
/* END POSTS */

/* CALNEDAR */
.calendar-title {
    font-weight: 800;
    font-size: 26px;
    margin-bottom: 30px;
}

.calendar-subtitle {
    font-weight: 600;
    font-size: 14px;
    margin-right: 17px;
}

.fc-col-header-cell .fc-scrollgrid-sync-inner {
    text-align: end;
    background-color: #FBFBFB;
}

.fc .fc-col-header-cell-cushion {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--bg-color-lightgrey);
    text-decoration: none;
    margin: 15px;
}

.fc .fc-daygrid-day-number {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 14px;
    color: var(--bg-color-lightgrey);
    text-decoration: none;
    padding: 15px !important;
}

.fc-daygrid-event.scheduled {color: var(--bg-color-orange);}
.fc-daygrid-event.draft {color: var(--bg-color-lightgrey);}
.fc-daygrid-event.done {color: var(--bg-color-green);}
.fc-daygrid-event.pending_internal_approval {color:lightcoral}
.fc-daygrid-event.pending_client_approval {color: var(--bg-color-blue);}
.fc-daygrid-event.posted {color: red;}
.fc-daygrid-event.internally_approved {color: var(--bg-color-influeeColor);}

.scheduled .fc-daygrid-event-dot {border-color: var(--bg-color-orange);}
.draft .fc-daygrid-event-dot {border-color: var(--bg-color-lightgrey);}
.done .fc-daygrid-event-dot {border-color: var(--bg-color-green);}
.pending_internal_approval .fc-daygrid-event-dot {border-color: lightcoral;}
.pending_client_approval .fc-daygrid-event-dot {border-color: var(--bg-color-blue);}
.posted .fc-daygrid-event-dot {border-color: red;}
.internally_approved .fc-daygrid-event-dot {border-color: var(--bg-color-influeeColor);}

.fc .fc-button {
    background-color: #fff !important;
    border-color: rgb(221, 221, 221) !important;
    color: var(--bg-color-lightgrey) !important; 
}

.fc .fc-button-group > .fc-button {
    background-color: #fff;
    border-color: rgb(221, 221, 221);
}

.fc-event-title,
.fc-event-time {
    /* font-family: 'Lato'; */
    font-weight: 900 !important;
    font-size: 12px !important;
    line-height: 14px !important;
}

.add-new-post {
    margin-bottom: 13px;
}

.loading-calendar {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.164);
    z-index: 15;
    justify-content: center;
    align-items: center;
}

.loading-calendar.show {
    display: flex;
}
/* END CALNEDAR */

/* FILE UPLOAD */
.drag-n-drop {
    height: 300px;
    width: 100%;
    background-color: #F7F7F7;
    border: 1px dashed #DADADA;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}

.drag-n-drop .files-bg {
    position: absolute;
    top: 15%;
    background-image: url(../img/files.png);
    background-repeat: no-repeat;
    background-position: center;
    height: 100px;
    width: 100%;
}

.drag-n-drop .text-bg {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.28px;
    color: var(--bg-color-blue);
}

.drag-n-drop .text-bg > div:nth-child(2) {
    line-height: 36px;
    margin-bottom: 11px;
}

.drag-n-drop .upload-btn {
    height: 60px;
    width: 60px;
    background-color: var(--bg-color-blue);
    border-radius: 50%;
    background-image: url(../img/uploadsvg.svg);
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 5px;
}

.drag-n-drop input[type="file"] {
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
}

.cropped-image {
    width: auto;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
    
    max-width: 100%;
}

.original-wrapper {
    border: 1px solid var(--bg-color-darkgrey);
    border-radius: 4px;
    padding: 10px;
}

.original-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.original-image {
    cursor: pointer;
    object-fit: contain;
    overflow: hidden;
    height: auto;
    width: 150px;
    margin: 0px 5px 5px 0px;
}
/* END FILE UPLOAD */

/* SORT IMAGES */
.sort-container {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
}
.sort-container > div {
    margin-bottom: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.sort-item {
    display: inline-block;
    position: relative;
    z-index: 100;
    height: 100%;
}

.sort-image {
    z-index: 100;
    object-fit: contain;
    overflow: hidden;
    max-height: 150px;
    max-width: 150px;  
    height: 100%;
}

.video-icon{
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);
    height: 25px;
    width: 25px;
    background-image: url(../img/play.svg);
}

.delete-media {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    background-color: transparent;
    background-image: url(../img/close.svg);
    background-position: center;
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    filter: invert(60%) sepia(38%) saturate(5373%) hue-rotate(356deg) brightness(98%) contrast(97%) drop-shadow(2px 2px 4px black);
    transition: all 200ms ease-in-out;
}

.delete-media:hover {
    transform: scale(1.2);
}
/* END SORT IMAGES */

/* PREVIEW */
.preview-title {
    color: var(--bg-color-blue);
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 26px;
    line-height: 35px;
}

.preview-status {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 10px;
    color: #fff;
    padding: 5px 15px;
    text-transform: uppercase;
    width: fit-content;
    margin-top: 10px;
}

.preview-date {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    margin-top: 10px;
}

.preview-buttons {
    display: flex;
    flex-direction: column;
}

.preview-button {
    width: 52px;
    height: 59px;
    background-color: #F7F7F7;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
}

.preview-button.facebook {
    background-image: url(../img/fb.svg);
}

.preview-button.instagram {
    background-image: url(../img/ig.svg);
}

.preview-button.linkedin {
    background-image: url(../img/in.svg);
}

.preview-button.tiktok {
    background-image: url(../img/tt.svg);
}

.preview-button.active {
    background-color: var(--bg-color-blue);
}

.preview-button.disabled {
    filter: opacity(0.5);
}

.preview-button.edit {
    background-image: url(../img/post.svg);
}

.preview-button.link {
    background-image: url(../img/link2.svg);
}

.preview-button.share {
    background-image: url(../img/share.svg);
}

.preview-button.duplicate {
    background-image: url(../img/duplicate.svg);
}

.preview-button.delete {
    background-image: url(../img/delete.svg);
    margin-bottom: 40px;
}

.copy-btn {
    border: none;
    background-color: #F2F2F2;
    border-radius: 4px;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--bg-color-darkgrey);
    padding: 6px 40px;
}
/* END PREVIEW */

/* DEVICE */
.marvel-device .screen {
    overflow: scroll !important;
    display: flex !important;
    flex-direction: column;
}

.marvel-device .screen::-webkit-scrollbar {
    display: none;
}

.marvel-device .screen {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.marvel-device .device {
    margin: 40px 0 20px;
}

.device-social {
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    width: 120px;
    height: 30px;
    margin: 0px 10px 0px;
}

.device-social.facebook {
    background-image: url(../img/facebook.png);
}

.device-social.instagram {
    background-image: url(../img/Instagram.png);
}

/* Facebook */
.fb-header {
    display: flex;
    margin-bottom: 12px;
    padding: 12px 16px 0px;
}

.fb-logo {
    border-radius: 50%;
    height: 40px; 
    width: 40px;
    margin-right: 8px;
}

.fb-brand-name {
    font-size: "Segoe UI Historic", "Segoe UI", Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #050505;
    line-height: 19.9995px;
}

.fb-date {
    font-size: "Segoe UI Historic", "Segoe UI", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: rgb(101, 103, 107);
    line-height: 16.0004px
}

.fb-post-caption {
    /* font-size: Helvetica, Arial, sans-serif; */
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    color: #050505;
    margin: 4px 16px 16px;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: initial;
}

.fb-post-caption.showAll {
    display: block;
}

.fb-see-more {
    text-align: end;
    margin: 0px 16px 16px;;
    color: rgb(24, 118, 242);
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
}

.fb-media {
    margin-bottom: 20px;
}

.fb-footer {
    margin: 0 12px;
    border-top: 1px solid rgb(217, 217, 217);
}

.fb-like,
.fb-comment,
.fb-share {
    flex-grow: 1;
    padding: 6px 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fb-like {
    cursor: pointer;
}

.fb-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    filter: opacity(0.6);
    margin-right: 5px;
}

.fb-like .fb-icon {
    background-image: url(../img/fb-thumb-up.png);
    height: 15px;
    width: 15px;
}

.fb-like.active .fb-icon {
    filter: invert(47%) sepia(16%) saturate(6584%) hue-rotate(201deg) brightness(103%) contrast(99%);
    animation: hop 500ms;
}

.fb-icon ~ div {
    font-weight: 600;
}

.fb-like.active div:nth-child(2) {
    color: var(--bg-color-blue);
}

.fb-comment .fb-icon {
    height: 18px;
    width: 18px;
    background-image: url(../img/fb-comment.png);
}

.fb-share .fb-icon {
    background-image: url(../img/fb-share.png);
    height: 18px;
    width: 18px;
}

.device-navigation {
    position: sticky;
    margin-top: auto;
    bottom: 0;
    left: 0;
    background-color: #fff;
    align-self: stretch;
    display: flex;
    justify-content: space-around;
    border-top: 1px solid rgb(217, 217, 217);
}

.device-navigation div {
    margin: 10px 0 25px;
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    flex-grow: 1;
}

.device-navigation .fb-news {
    background-image: url(../img/news.png);
    filter: invert(35%) sepia(27%) saturate(7055%) hue-rotate(204deg) brightness(99%) contrast(91%);
    position: relative;
}

.device-navigation .fb-news::before {
    content: "";
    top: 0;
    left: -2px;
    right: -1px;
    height: 2px;
    background-color: #000;
    position: absolute;
    top: -11px;
}

.device-navigation .fb-user {background-image: url(../img/fb-user.png);}
.device-navigation .fb-group {background-image: url(../img/fb-group.png);}
.device-navigation .fb-flag {background-image: url(../img/fb-flag.png);}
.device-navigation .fb-bell {background-image: url(../img/fb-bell.png);}
.device-navigation .fb-menu {background-image: url(../img/fb-menu.png);}

/* Instagram */
.ig-header {
    display: flex;
    margin-bottom: 12px;
    padding: 12px 12px 0px;
}

.ig-logo {
    border-radius: 50%;
    height: 32px; 
    width: 32px;
    margin-right: 10px;
}

.ig-brand-name {
    font-size: "Segoe UI Historic", "Segoe UI", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: rgb(38, 38, 38);
    line-height: 18px;
    margin-right: 5px;
}

.ig-post-title {
    font-size: "Segoe UI Historic", "Segoe UI", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: rgb(38, 38, 38);
    line-height: 16px;
}

.ig-media {
    margin-bottom: 8px;
}

.device-navigation .ig-home {background-image: url(../img/ig-home.svg);}
.device-navigation .ig-search {background-image: url(../img/ig-hearth.svg);}
.device-navigation .ig-add {background-image: url(../img/ig-add.svg);}
.device-navigation .ig-store {background-image: url(../img/ig-store.svg);}
.device-navigation .ig-user {background-image: url(../img/ig-user.jpg);}

.ig-hearth {
    background-image: url(../img/ig-hearth.svg);
    height: 24px;
    width: 24px;
    margin-left: 12px;
    cursor: pointer;
}

.ig-hearth.active {
    background-image: url(../img/ig-hearth-red.svg);
}

.ig-comment {
    background-image: url(../img/ig-comment.svg);
    height: 24px;
    width: 24px;
    margin-left: 15px;
}

.ig-send {
    background-image: url(../img/ig-send.svg);
    height: 24px;
    width: 24px;
    margin-left: 15px;
}

.ig-footer {
    padding: 12px 12px 0px
}

.ig-caption {
    color: rgb(38, 38, 38);
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: initial;
}

.ig-caption.showAll {
    display: block;
}

.ig-more {
    color: rgb(142, 142, 142);
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    cursor: pointer;
}

.ig-date {
    color: rgb(142, 142, 142);
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    text-transform: uppercase;
    margin-top: 10px;
}
/* END DEVICE */

/* CAROUSEL */
.carousel .slide {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
/* END CAROUSEL */

/* APPROVE */
.approve-container {
    width: 700px;
    max-width: 90%;
    margin: auto;
}

.approve-title {
    color: var(--bg-color-blue);
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 26px;
    line-height: 35px;
    margin-bottom: 50px;
    margin-top: 20px;
}
/* END APPROVE */

/* CAMPAIGN */
.campaign-background {
    background-color: #f2f2f2;
    position: relative;
    z-index: 20;
}

.campaign-background-helper {
    position: absolute;
    background-color: #f2f2f2;
    top: 0;
    left: 0;
    width: calc(100%);
    height: calc(100vh - 80px );
    translate: 0px -30px;
    z-index: 10;
}

.campaign-logo {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 18px;
    background-color: #DADADA;
    border-radius: 4px;
    height: 50px;
    width: 50px;
    margin-right: 10px;
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.campaign-name {
    word-break: break-word;
}

.campaign-title {
    font-family: 'Open Sans';
    font-weight: 800;
    font-size: 26px;
    line-height: 35px;
    color: var(--bg-color-darkgrey);
    margin-bottom: 25px;
}

.campaign-subtitle {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 14px;
    color: var(--bg-color-darkgrey);
    text-transform: uppercase;
}
    
.campaign-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    padding: 0 12px 12px;
    border-radius: 3px;
}

.campaign-company {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--bg-color-darkgrey);
    margin-bottom: 12px;
    margin-top: 12px;
}

.campaign-top {
    display: flex;
    align-items: center;
}

.campaign-date {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 12px;
    color: var(--bg-color-darkgrey);
}

.campaign-status {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #FFFFFF;
    padding: 5px 15px;
    margin-left: 10px;
    text-transform: uppercase;
}

.campaign-status.public {
    background-color: var(--bg-color-green);
}
.campaign-status.draft {
    background-color: var(--bg-color-blue);
}
.campaign-status.review {
    background-color: var(--bg-color-orange);
}
.campaign-status.done {
    background-color: var(--bg-color-darkgrey);
}
.campaign-status.private {
    background-color: tomato;
}

.campaign-item-title {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: var(--bg-color-darkgrey);
    margin-bottom: 5px;
    cursor: pointer;
}

.campaign-item-subtitle {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: var(--bg-color-darkgrey);
}

.campaign-description {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: var(--bg-color-darkgrey);
    margin-bottom: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.campaign-orange-text {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #F6790B;
}

.campaign-social-icon {
    height: 24px;
    width: 24px;
    background-repeat: no-repeat;
    margin-right: 8px;
}

.campaign-social-icon.facebook {
    background-image: url(../img/fb-logo.svg);
}

.campaign-social-icon.instagram {
    background-image: url(../img/ig-logo.svg);
}

.campaign-image {
    max-width: 250px;
    margin-bottom: 10px;
    margin-right: 10px;
    position: relative;
}

.campaign-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.application-item {
    background-color: #fff;
    border-radius: 3px;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.campaign-label {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 16px;
    color: var(--bg-color-darkgrey);
    margin-bottom: 5px;
}

.application-email {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--bg-color-darkgrey);
    margin-bottom: 5px;
}

.application-name {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: var(--bg-color-darkgrey);
    margin-bottom: 10px;
}

.application-status {
    font-family: 'Open Sans';
    font-size: 15px;
    color: var(--bg-color-darkgrey);
}

.application-status span {
    font-weight: 600;
}

.invite-item {
    padding: 10px;
    background-color: #f2f2f2;
    border-radius: 3px;
    margin-bottom: 10px;
    transition: all 200ms ease-in-out;
    cursor: pointer;
    margin: 0 10px 10px 0;
}

.invite-name {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 15px;
    color: var(--bg-color-darkgrey);
}

.invite-email {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 13px;
    color: var(--bg-color-darkgrey);
}

.invite-item.active {
    transform: scale(1.08);
    outline: 1px solid var(--bg-color-green);
}

.creator-item {
    height: 100%;
    background-color: #fff;
    padding: 12px;
    border-radius: 3px;
}

.creator-item-image {
    width: 95px;
    max-width: 100%;
    border-radius: 50%;
}

.creator-item-email {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--bg-color-lightgrey);
}

.creator-item-name {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: var(--bg-color-darkgrey);
    padding-bottom: 30px;
}
/* END CAMPAIGN */

/* SOCIAL MEDIA PAGE */
.social-media-page--title {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
}

.social-media-page--item {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f2f2f2;
    border-radius: 3px;
    margin-bottom: 10px;
    transition: all 200ms ease-in-out;
    cursor: pointer;
    margin: 0 10px 10px 0;
    width: 100%;
}

.social-media-page--name {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 15px;
    color: var(--bg-color-darkgrey);
}

.social-media-page--image {
    height: 40px;
    width: 40px;
    max-width: 100%;
    border-radius: 50%;
}

.social-media-page--item.active {
    transform: scale(1.08);
    outline: 1px solid var(--bg-color-green);
}

.social-media-page--item.disabled {
    cursor: no-drop;
    outline: 1px solid tomato;
}
/* END SOCIAL MEDIA PAGE */

/* PHONE */
.marvel-device.iphone-x {
    max-width: calc(100% - 50px);
}

.marvel-device.iphone-x .notch {
    left: 50% !important;
    transform: translateX(-50%) !important;
}
/* END PHONE */

/* CRON ERRORS */
.cron-error {
    position: relative;
    border: none;
    background-color: transparent;
    height: 30px;
    width: 30px;
    background-image: url(../img/exclamation-mark.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 190;
    transition: all 200ms ease-in-out;
}

.cron-error.error {
    filter: invert(25%) sepia(98%) saturate(6360%) hue-rotate(2deg) brightness(99%) contrast(130%);
}

.cron-error.warning {
    filter: invert(67%) sepia(99%) saturate(444%) hue-rotate(358deg) brightness(99%) contrast(105%);
}

.cron-error:hover {
    scale: 1.15;
}

.cron-content {
    display: none;
    position: absolute;
    left: 10px;
    right: 10px;
    top: 75px;
    z-index: 200;
    padding: 10px;
    border-radius: 4px;
    color: rgb(20, 15, 15);
}

.cron-content.error {
    background-color: #ffcfcf;
    border: 2px solid #ff0000;
}

.cron-content.warning {
    background-color: #fffecf;
    border: 2px solid rgb(255, 196, 0);
}

.cron-content.show {
    display: block;
}
/* END CRON ERRORS */

@keyframes hop {
    40% {
        transform: rotate(-15deg) scale(1.2);
    }
    80% {
        transform: rotate(-15deg) scale(1.2);
    }
    100% {
        transform: rotate(0deg) scale(1);
    }
}

@media only screen and (min-width: 1200px) {
    .campaign-background-helper {
       width: calc(100% + 100px);
    }
}

@media only screen and (max-width: 991px) {
    .calendar {
        order: 1;
    }

    .posts {
        display: flex;
        overflow-y: hidden;
    }

    /* width */
    .posts::-webkit-scrollbar {
        height: 3px;
    }

    .calendar-post {
        flex-direction: column;
        margin-right: 10px;
        margin-left: 10px;
    }

    .calendar-post:first-child {
        margin-left: 0px;
    }

    .calendar-post-img {
        margin-right: 0px;
    }

    .calendar-post-status {
        align-self: baseline;
        margin-bottom: 5px;
        margin-right: 0px;
    }
}

@media only screen and (max-width: 767px) {
    .campaign-background-helper {
       height: 100%;
       translate: 0px 0px;
    }

    .campaign-background .small-title {
        margin-top: 30px;
    }
}

@media only screen and (max-width: 576px) {
    .post-container {
        padding: 10px;
    }

    .create-title::before {
        display: none;
    }
    
    .drag-n-drop .text-bg {
        top: 20%;
    }

    .preview-buttons {
        flex-direction: row;
    }
}