.react-tabs__tab-list {
    margin-bottom: 1px !important;
    border-bottom: none !important;
}

.react-tabs__tab {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--bg-color-darkgrey) !important;
    border-radius: 5px 5px 0 0;
    background-color: #F7F7F7;
    margin-right: 4px;
    border: 1px solid #DADADA !important;
    border-bottom: none !important;
    padding: 6px 30px !important;
}

.react-tabs__tab--selected {
    font-weight: 600;
}

.react-tabs__tab--selected::after {
    content: '';
    position: absolute;
    height: 5px;
    left: -1px !important;
    right: -1px !important;
    bottom: -5px;
    background-color: #fff;
}

.react-tabs__tab--selected:first-child::after {
    border-left: 1px solid #DADADA;
}

.chat-container {
    margin-bottom: 40px;
}

.chat-content {
    border: 1px solid #DADADA;
    border-radius: 8px;
    padding: 15px 5px 15px 15px;
    background-color: #fff;
}

.chat-messages {
    height: 300px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding-right: 10px;
}

/* width */
.chat-messages::-webkit-scrollbar {
    width: 3px;
}
  
/* Track */
.chat-messages::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #DADADA;
    border-radius: 10px;
}
  
/* Handle */
.chat-messages::-webkit-scrollbar-thumb {
    background: var(--bg-color-orange);
    border-radius: 10px;
}

.message-container {
    display: flex;
    align-items: flex-end;
    margin-top: 10px;
}

.my-message {
    align-self: flex-end;
}

.opponent-message {
    align-self: flex-start;
}

.message {
    padding: 16px 26px 15px 19px;
    margin: 0px 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.message-line {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: var(--bg-color-lighgrey);
    min-height: 16px;
}

.my-message .message {
    background-color: var(--bg-color-orange);
    border-bottom-left-radius: 8px;
    color: #fff;
}

.opponent-message .message {
    background-color: #F7F7F7;
    border-bottom-right-radius: 8px;
}

.message-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: var(--bg-color-lighgrey);
}

.user-photo {
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.user-no-photo {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--bg-color-darkgrey);
}

.my-message .message-user {
    order: 1;
}

.chat-footer {
    margin-top: 5px;
}

.chat-footer .send-btn {
    border: none;
    background-color: var(--bg-color-orange);
    border-radius: 50% !important;
    background-image: url(../img/send.svg);
    background-repeat: no-repeat;
    background-position: center;
    height: 37px;
    width: 37px;
    margin-left: 5px !important;
}

.chat-footer .media-btn {
    border: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    height: 37px;
    width: 37px;
}

.chat-footer .media-btn.img {
    background-image: url(../img/img.svg);
}

.chat-footer .media-btn.chain {
    background-image: url(../img/link.svg);
}

.chat-footer .media-btn.file {
    background-image: url(../img/file.svg);
}

.chat-footer .input-group {
    padding: 5px 7px;
    border: 1px solid #DADADA;
    border-radius: 100px;
}

.chat-footer .input-group input,
.chat-footer .input-group textarea {
    border: none;
}

.chat-footer .input-group textarea {
    resize: none;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.chat-footer .input-group textarea::-webkit-scrollbar {
    display: none;
  }

.chat-footer .input-group input:focus,
.chat-footer .input-group textarea:focus {
    box-shadow: none;
}