.login-container {
    margin: 120px 0;
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-container .login-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 120px;
    text-align: center;
}

.login-form {
    width: 470px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0 20px;
    margin-bottom: 100px;
}

.login-container .login-title {
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #4B4B4B;
    text-transform: uppercase;
}

.login-container .login-subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 40px;
}

.login-container .form-control,
.login-container .form-select {
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid rgb(185, 185, 185) !important;
}

.login-container .form-floating>label{
    font-weight: 400;
    font-size: 14px;
}

.login-container .form-floating>.form-control:focus~label,
.login-container .form-floating>.form-select:focus~label {
    color: #438AFE;
    opacity: 1;
}

.login-container .form-control:focus,
.login-container .form-select:focus {
    box-shadow: none;
    border-bottom-color: #438AFE !important;
    border-bottom-width: 1px !important;
}

.login-container .form-control.is-invalid,
.login-container .form-select.is-invalid {
    border-bottom-color: red !important;
}

.login-container .login-noAcc {
    font-weight: 400;
    font-size: 13px;
    line-height: 50px;
}


.login-container .login-noAcc .login-link {
    font-size: 14px;
    line-height: 36px;
}

.login-link {
    font-family: 'Open Sans';
    text-decoration: none;
    color: #438AFE;
    font-weight: 400;
    font-size: 13px;
    line-height: 36px;
}

.login-btn {
    background-color: #F6790B;
    border: none;
    padding: 12px 33px;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    width: fit-content;
    text-transform: uppercase;
}

.login-container input:-webkit-autofill,
.login-container input:-webkit-autofill:hover, 
.login-container input:-webkit-autofill:focus, 
.login-container input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.login-bottom-text {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
}