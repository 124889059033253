@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import "./custom.css"; 
@import "./login.css";
@import "./chat.css";
@import "./devices.min.css";
@import "./editor.css";

:root {
    --bg-color-orange: #F6790B;
    --bg-color-green: #279F14;
    --bg-color-blue: #438AFE;
    --bg-color-lightgrey: #686868;
    --bg-color-darkgrey: #4B4B4B;
    --bg-color-influeeColor: #738CF0;
}

body {
    font-family: 'Open Sans', sans-serif;
    color: var(--bg-color-lightgrey);
    background-color: #fff;
}

body.gray,
body.campaign,
body.creator {
    background-color: #f2f2f2;
}

.main-content {
    /* margin-bottom: 20px !important; */
}

/* HEADER */
.navbar {
    background-color: var(--bg-color-orange) !important;
    margin-bottom: 30px !important;
    padding: 0px !important;
    color: #fff;
    min-height: 80px;
}

body.campaign .navbar {
    background-color: var(--bg-color-influeeColor) !important;
}

body.creator .navbar {
    background-color: var(--bg-color-green) !important;
}

.navbar-logo-container {
    background: linear-gradient(116.07deg, #F3B555 12.6%, var(--bg-color-orange) 55.72%);
    border-right: 4px solid #fff;
    display: flex;
    align-items: center;
}

body.campaign .navbar-logo-container {
    background: linear-gradient(116.07deg, #A5B6FF 12.6%, var(--bg-color-influeeColor) 55.72%);
}

body.creator .navbar-logo-container {
    background: linear-gradient(116.07deg, #62D23A 12.6%, var(--bg-color-green) 55.72%);
}

.navbar-logo {
    display: block;
    background-image: url(../img/smm360.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 160px;
    height: 30px;
    background-color: transparent;
    border: none;
    margin: 25px 30px 23px 20px
}

.navbar-nav a.nav-link {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #fff;
    text-decoration: underline;
    margin: 17px 0px 12px 0px !important;
    padding: 14px 0px !important;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    min-width: 240px;
    text-align: center;
}

.dropdown a.nav-link {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-right: 35px;
}

.header-select {
    background-color: var(--bg-color-orange);
    color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    min-width: 225px;
    padding: 5px 17px;
    font-weight: 600;
    font-size: 18px;
}

body.campaign .header-select {
    background-color: var(--bg-color-influeeColor);
}

.navbar-toggler {
    margin-right: 10px;
}
/* END HEADER */
@media only screen and (max-width: 1370px) {
    .navbar-logo {
        transform: scale(0.8);
    }  

    .navbar-nav a.nav-link {
        min-width: fit-content;
        padding-left: 10px !important;
        padding-right: 10px !important;
        font-size: 16px;
        line-height: 18px;
    }

    .header-select {
        min-width: 155px;
        font-size: 15px;
        margin-left: 15px;
    }
}

@media only screen and (min-width: 1200px) {
    .main-content {
        margin: 0 100px;
    }
}

@media only screen and (min-width: 992px) {
    .dropdown-menu.show {
        left: auto;
        right: 30px;
    }
}

@media only screen and (max-width: 991px) {
    body .navbar {
        background: linear-gradient(116.07deg, #F3B555 12.6%, var(--bg-color-orange) 55.72%);
    }
    body.campaign .navbar {
        background: linear-gradient(116.07deg, #A5B6FF 12.6%, var(--bg-color-influeeColor) 55.72%);
    }
    body.creator .navbar {
        background: linear-gradient(116.07deg, #62D23A 12.6%, var(--bg-color-green) 55.72%);
    }

    .navbar-logo-container {
        background: none !important; 
        border-right: none;
    }

    .navbar-nav {
        margin-top: 20px;
    }

    .navbar-nav a.nav-link {
        border-right: none;
        margin: 10px !important;
        padding: 0px !important;
    }

    .navbar-collapse .dropdown {
        text-align: center;
    }

    .dropdown a.nav-link {
        margin: 10px auto;
    }

    .dropdown-menu.show {
        left: 50%;
        transform: translateX(-50%);
    }
}

@media only screen and (max-width: 576px) {
    .navbar-logo {
        width: 100px;
        height: 20px;
    }
    
    .header-select {
        display: none;
    }
}