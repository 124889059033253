.tui-image-editor-container .tui-image-editor-header {
    display: none;
}

.tui-image-editor-container .tui-image-editor-submenu {
    white-space: normal !important;
}

.tui-colorpicker-palette-button {
    display: block;
    width: 16px;
    height: 16px;
}

.tui-colorpicker-palette-preview {
    overflow: hidden;
}

.tui-image-editor-container.bottom .tui-image-editor-submenu > div {
    opacity: 0.7;
}

.tui-image-editor-container .tui-image-editor-help-menu.top {
    max-width: 100%;
    display: flex !important;
    flex-wrap: wrap;
    height: fit-content !important;
}